<template>
    <div>
        <h2 class="text-3xl font-semibold text-black mb-4">Milestones</h2>

        <SectionLoading v-if="loading" text="Milestones data loading..." />

        <div v-else>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6" v-if="milestones.length > 0">
                <div class="card siteCard p-2" v-for="(milestone, index) in milestones" :key="index">
                    <div>
                        <div class="flex items-center space-x-4">
                            <img :src="milestone.release && milestone.release.cover_image_path" alt="Cover"
                                class="w-24 h-24 object-cover rounded-md">

                            <div class="w-full">
                                <h2 class="text-lg font-semibold">{{
                                    milestone.release && milestone.release.title ? milestone.release.title : 'No Release Title'
                                }}</h2>
                                <p class="text-gray-500 mb-1 text-sm">Track: {{ milestone.track && milestone.track.title }}</p>
                                <span class="text-gray-600 text-xs">Split Percentage: {{ milestone.split_percentage }}%</span>
                                <div class="mt-2 relative">
                                    <div class="w-full bg-green-500 h-1 rounded absolute"
                                        :style="`width: ${milestone.split_percentage}%`"></div>
                                    <div class="w-full bg-green-400 h-1 rounded opacity-20"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="card siteCard p-2">
                <div class="flex items-center space-x-4">
                    <div class="w-full p-3">
                        <h2 class="text-lg font-semibold">No Milestone Data</h2>
                        <p class="text-gray-500 mb-1">
                            You currently have no milestone data. Please check back later.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SectionLoading from "@/components/SectionLoading.vue";

export default {
    data() {
        return {
            milestones: '',
            loading: false,
        }
    },
    components: {
        SectionLoading,
    },
    mounted() {
        this.getSplitData();
    },
    methods: {
        getSplitData() {
            this.loading = true;

            let fetch = {
                path: `/artist/splits-optimized`,
            };
            this.$store
                .dispatch("getRequest", fetch)
                .then((resp) => {
                    this.milestones = resp.data?.data || [];

                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                    if (err.response) {
                        this.$vs.notify({
                            title: "Milestone Data",
                            text: err.response.data.message,
                            color: "warning",
                            icon: "error",
                            position: "bottom-center",
                        });
                    } else {
                        this.$vs.notify({
                            title: "Milestones Data",
                            text: "Unable to get Milestones Data",
                            color: "dark",
                            icon: "error",
                            position: "bottom-center",
                        });
                    }
                });
        }
    }
}
</script>